import React from 'react'
import { Link } from 'gatsby'
import {css} from 'emotion'
import Styled from 'react-emotion'
import Row from './Row';


const styles = {
  wrapper: css`
    ${tw`flex z-999 fixed pin-t w-screen py-4 items-center bg-white`};
    // height: 3rem;
  `,
  row: css`
    ${tw`flex w-full px-4 lg:px-0 flex-wrap max-w-row mx-auto items-center justify-between`};
  `,
  orderButton: css`
    ${tw`px-6 py-2 rounded-full bg-black text-sm text-white cursor-pointer no-underline font-sans font-medium`};
    transition: 0.25s ease-out;
      will-change: box-shadow transform;
      &:hover{
        transform: scale(1.01);
        transition: all 0.25s ease-in;
        box-shadow: 0 20px 30px -5px rgba(0,0,0,0.2);
      }
  `,
}

const NavList = Styled.ul`
  ${tw`list-reset m-0 flex items-center font-sans`};
  li{
    ${tw`mb-0`};
    // opacity: 0.6;
    cursor: pointer;
  }
  li:hover{
    opacity: 1;
  }

`

/* global tw */
const Header = () => (
  <navbar className={styles.wrapper}>
    <div className={styles.row}>
      <div className={css`${tw`w-1/4`};`}>
       <img className={css`${tw`mb-0`}`} src={require('../images/logo-original.svg')} alt="Poke Bowl Logo"/>
      </div>

      <div className={css`${tw`lg:flex hidden w-auto`}`}>
        <NavList>
          {/* <li className={css`${tw`mb-0 lg:mr-8`}`}>Our Story</li> */}
          {/* <li className={css`${tw`mb-0 lg:mr-8`}`}>Locations</li> */}
          <li className={css`${tw`flex flex-wrap items-center mb-0 lg:mr-8`}`}>
            {/* <img src={require('../images/facebook.svg')} alt="Like Poke Bowls page on Facebook" /> */}
            {/* <img src={require('../images/instagram.svg')} alt="Like Poke Bowls page on Instagram" /> */}
            {/* <ul className={css`${tw`flex flex-wrap items-center list-reset m-0 p-0`}`}>
              <li className={css`${tw`mx-8`}`}>
                <img src={require('../images/facebook.svg')} alt="Like Poke Bowls page on Facebook"/>
              </li>
              <li className={css`${tw`mx-8`}`}>
                <img src={require('../images/instagram.svg')} alt="Like Poke Bowls page on Instagram"/>
              </li>
            </ul> */}
          </li>
          {/* <li className={css`${tw`mb-0 lg:mr-8`}`}>Franchise</li> */}
          {/* <li className={css`${tw`mb-0 lg:mr-8 px-6 py-4 rounded-full bg-black text-sm font-medium text-white`}`}>Order Delivery</li> */}
          <li className={css`${tw`mb-0 w-auto lg:mr-8`}`}>
            <a href="https://ordering.chownow.com/order/13891/locations"
              target="_blank"
              className={styles.orderButton}>
              Order Delivery
          </a>
          </li>
        </NavList>
      </div>
    </div>
  </navbar>
)

export default Header
